import Mojo from "./Mojo";
import Navigation from "./Navigation";
import ServicesRow from "./ServicesRow";
import Info from "./Info";
import ContactsRow from "./ContactsRow";
import ContactForm from './ContactForm';
import GreenGradient from '../gradients/GreenGradient';
import RedGradient from '../gradients/RedGradient';
import BlueGradient from '../gradients/BlueGradient';
import { Helmet } from "react-helmet";


const App = () => {
  return (
    <div className="w-full h-full bg-background-black pb-40 relative overflow-hidden">
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href="../../public/favicon.ico" />
        <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="apple-touch-icon" href="../../public/favicon.ico" />
        <link rel="manifest" href="../../public/manifest.json" />
        <title>Байт-Про: Купить компьютеры, комплектующие и услуги в России - Ваш магазин ПК</title>
        <meta name="description" content="Байт-Про в Ярославле. Продажа компьютеров, комплектующих и сервисных услуг по всей России. Надежные ПК и профессиональная сборка под ваши нужды." />
        <meta name="keywords" content="Байт-Про, Ярославсль, компьютеры, комплектующие, ПК, купить ПК, сервисные услуги, Россия, сборка компьютеров, компьютерный магазин" />
        <html lang="ru" />
        <link rel="canonical" href="https://www.byte-pro.ru/" />
        <meta property="og:title" content="Купить компьютеры, комплектующие и услуги в Ярославле - Ваш магазин ПК" />
        <meta property="og:description" content="Продажа компьютеров, комплектующих и сервисных услуг по всей России. Надежные ПК и профессиональная сборка под ваши нужды." />
        <meta property="og:url" content="https://byte-pro.ru/" />
        <meta property="og:type" content="website" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <script type="application/ld+json">
          {`{
        "@context": "https://schema.org",
        "@type": "Store",
        "name": "Байт-Про в Ярославле. Сервис и продала ПК",
        "@id": "https://byte-pro.ru/",
        "url": "https://byte-pro.ru/",
        "telephone": "+7 4852 58 03 36",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Ленинградский проспект, д.33, оф.209а",
          "addressLocality": "Ярославль",
          "postalCode": "150044",
          "addressCountry": "RU"
        },
        "openingHoursSpecification": [{
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "opens": "09:00",
          "closes": "18:00"
        }]
      }`}
        </script>
        <meta name="robots" content="index, follow" />
      </Helmet>
    <div className='gradient overflow-hidden h-full'>
      <GreenGradient />
      <RedGradient />
      <BlueGradient />
    </div>
      <div className='background'>
        <div className='line line-1'/>
        <div className='line line-2'/>
        <div className='line line-3 hidden lg:block'/>
        <div className='line line-4 hidden lg:block'/>
      </div>
      <Navigation />
      <div className="page-padding body">
        <Mojo />
        <ServicesRow />
        <Info />
        <ContactsRow />
        {/*<ContactForm />*/}
      </div>
    </div>
  );
};

export default App;
