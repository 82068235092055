import LogoIcon from "../icons/LogoIcon";
import Button from "./Button";

const Navigation = () => {
    const handleNavigation = (e, targetId) => {
        e.preventDefault();
        const targetElement = document.querySelector(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    };

    return (
        <div className="py-8 page-padding flex items-center justify-between flex-nowrap navigation">
            <div className="md:scale-150">
                <LogoIcon/>
            </div>
            <div className="hidden lg:flex font-navigation">
                <a
                    className="nav-link"
                    href="#services"
                    onClick={(e) => handleNavigation(e, "#services")}
                >
                    Услуги
                </a>
                {/*<a*/}
                {/*    className="nav-link"*/}
                {/*    href="#clients"*/}
                {/*    onClick={(e) => handleNavigation(e, "#clients")}*/}
                {/*>*/}
                {/*    Клиенты*/}
                {/*</a>*/}
                <a
                    className="nav-link"
                    href="#contacts"
                    onClick={(e) => handleNavigation(e, "#contacts")}
                >Контакты</a>
            </div>
            <div className="flex justify-end items-center">
                <div className='flex flex-col'>
                    <a href="tel:+74852580336" className="hidden lg:block md:block mr-8">+7 (4852) 58-03-36</a>
                    <a href="tel:+74852580337" className="hidden lg:block md:block mr-8">+7 (4852) 58-03-37</a>
                </div>
                <Button text="Связаться с нами"/>
            </div>
        </div>
    );
};

export default Navigation;
