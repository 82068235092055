import Adress from "./Adress";
import Map from "./Map/Map";

const ContactsRow = () => {
  return (
    <div className="pb-28 flex justify-between flex-col lg:flex-row" id="contacts">
      <Map />
      <Adress />
    </div>
  );
};

export default ContactsRow;
