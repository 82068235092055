import Button from "./Button";
import AchivmentsGrid from "./AchivmentsGrid";
import PaymentsRow from "./PaymemtsRow";

const title = "Индивидуальный подход к каждому клиенту";

const Info = () => {
  return (
    <div>
      <div className="flex flex-col lg:flex-row items-center pr-10 pt-20 lg:pt-36 pb-20 lg:pb-16 justify-between">
        <div className="flex flex-col items-center lg:mb-0 mb-20">
          <div className="big-title mb-7">{title}</div>
          {/*<Button text={'Заказать услугу'} />*/}
        </div>
        <AchivmentsGrid id="clients" />
      </div>
      {/*<PaymentsRow />*/}
    </div>
  );
};

export default Info;
