const BlueGradient = () => {
    return (
        <div className='blue-gradient'>
            <svg width="651" height="1292" viewBox="0 0 651 1292" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.3" filter="url(#filter0_f_306_6741)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M-1.77109 320.676C97.141 326.037 181.711 383.366 243.471 460.813C305.228 538.258 344.695 633.909 325.503 731.085C305.65 831.612 240.828 925.018 144.756 960.653C56.1151 993.531 -34.5803 941.897 -116.738 895.118C-189.516 853.681 -250.365 798.41 -278.645 719.582C-312.67 624.738 -340.233 515.069 -282.778 432.291C-222.316 345.18 -107.654 314.938 -1.77109 320.676Z"
                          fill="url(#paint0_linear_306_6741)"/>
                </g>
                <defs>
                    <filter id="filter0_f_306_6741" x="-636" y="0" width="1286.39" height="1291.03"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="160" result="effect1_foregroundBlur_306_6741"/>
                    </filter>
                    <linearGradient id="paint0_linear_306_6741" x1="-371.742" y1="274.048" x2="-68.2106" y2="67.7098"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#41F2D2"/>
                        <stop offset="1" stopColor="#38DBFF"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default BlueGradient;
