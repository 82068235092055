import {mapIcons} from "../utils/iconMapper";

const ServiceCard = ({ title, body, price, icon, setModalContent }) => {
  const Icon = mapIcons(icon);
  return (
    <div
      className="p-5 service-card-boder lg:w-52 lg:h-56 h-44 text-lg mr-3 relative flex flex-col cursor-pointer first:mt-0 mt-5 lg:mt-0 bg-[#253435] opacity-75"
      onClick={() => setModalContent()}
    >
      <div className="mb-3">{title}</div>
      {body ? <div className="opacity-50 text-sm">{body}</div> : null}
      <div className="mt-5 mb-3 flex grow items-end justify-center">
        <Icon
            sx={{
              color: "rgb(65, 242, 210)",
              scale: "2.5"
            }}
            fontSize="inherit"
        />
      </div>
    </div>
  );
};

export default ServiceCard;
