import ComputerIcon from "@mui/icons-material/Computer";
import PrintIcon from '@mui/icons-material/Print';
import BuildIcon from '@mui/icons-material/Build';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import TerminalIcon from '@mui/icons-material/Terminal';

export const mapIcons = (icon) => {
    switch(icon) {
        case 'computer': {
            return ComputerIcon
        }
        case 'printer': {
            return PrintIcon
        }
        case 'repair': {
            return BuildIcon
        }
        case 'paint': {
            return FormatColorFillIcon
        }
        case 'terminal': {
            return TerminalIcon
        }
        default: {
            return ComputerIcon
        }
    }
}