const achievementsConfig = [
  {
    value: "23 года",
    description: "Успешной работы на рынке",
  },
  {
    value: ">900",
    description: "Завершённых проектов",
  },
  {
    value: "1 час",
    description: "Среднее время обработки заявки",
  },
  {
    value: "120",
    description: "Постоянных партнёров",
  },
];

export default achievementsConfig;
