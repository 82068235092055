const ServicesConf = [
    {
        title: "Диагностика и ремонт компьютеров и ноутбуков",
        price: "8 000",
        modalContent: {
            icon: "computer",
            title: "Диагностика и ремонт компьютеров и ноутбуков",
            description:
                "В мастерской компании «Байт-Про» вы можете заказать диагностику и ремонт компьютеров и ноутбуков. Мы быстро определим проблему и предложим варианты её устранения. Также проводим чистку и профилактику оборудования для предотвращения поломок и перегрева. Возможна замена или модернизация комплектующих. Гарантируем качественный сервис, доступные цены и предоставляем гарантию на все услуги.",
        },
    },
    {
        title: "ИТ-аутсорсинг. Обслуживание ПК и оргтехники",
        price: "15 000",
        modalContent: {
            icon: "repair",
            title: "ИТ-аутсорсинг. Обслуживание ПК и оргтехники",
            description: `
Компания «Байт-Про» предоставляет услуги технического обслуживания ПК и оргтехники. Мы обеспечиваем:\n
• Установку и настройку ПО и оборудования;\n
• Быстрое устранение неисправностей;\n
• Обеспечение безопасности данных;\n
• Восстановление утраченной информации;\n
• Удалённую поддержку.\n

Сотрудничество с нами даёт следующие преимущества:\n
• Мы берём на себя полный спектр задач: от контроля работоспособности до настройки сетей и ПО;\n
• Предотвращение проблем с вредоносным ПО;\n
• Выгодные предложения для разовых обращений;\n
• Оперативность и удобная форма взаимодействия.
    `,
        },
    },
    {
        title: "Ремонт и обслуживание принтеров, МФУ и оргтехники",
        price: "12 000",
        modalContent: {
            icon: "printer",
            title: "Ремонт и обслуживание принтеров, МФУ и оргтехники",
            description:
                "Мы выполняем диагностику и ремонт принтеров, МФУ и другой оргтехники. Специалисты компании оперативно выявят проблемы, заменят изношенные детали и проведут профилактическое обслуживание для предотвращения поломок. Регулярная чистка и смазка механизмов поможет сократить расходы на ремонт и простой оборудования.",
        },
    },
    {
        title: "Заправка и восстановление картриджей",
        price: "20 000",
        isHit: true,
        modalContent: {
            icon: "paint",
            title: "Заправка и восстановление картриджей",
            description: `Мы заправляем и восстанавливаем картриджи для черно-белых лазерных принтеров таких брендов, как HP, Canon, Samsung, Xerox, Ricoh, Kyocera и другие. 
Каждый картридж проверяется на исправность, неисправные узлы заменяются. Если ресурс полностью исчерпан, мы честно сообщаем об этом. Для ремонта используются только оригинальные детали и материалы. Запчасти и тонеры для 95% моделей всегда есть в наличии.`,
        },
    },
    {
        title: "Продажа программного обеспечения",
        price: "15 000",
        modalContent: {
            icon: "terminal",
            title: "Продажа программного обеспечения",
            description:
                "Компания «Байт-Про» предлагает широкий выбор лицензированного программного обеспечения для бизнеса и частных клиентов. Мы поможем подобрать подходящее решение, установить и настроить его под ваши нужды. Гарантируем доступные цены и поддержку на всех этапах использования.",
        },
    },
];

export default ServicesConf;
